const config = [
  { columns: 3, component: 'card' },
  { columns: 3, component: 'card' },
  { columns: 6, component: 'card', size: 'medium' },
  { columns: 12, component: 'additionalLinks' },
  { columns: 9, component: 'card', size: 'large', contentSide: 'left' },
  { columns: 3, component: 'subscribe' },
  { columns: 3, component: 'bestArticles' },
  { columns: 6, component: 'card', size: 'medium' },
  { columns: 3, component: 'card' },
  { columns: 3, component: 'banner' },
  { columns: 9, component: 'card', size: 'large', contentSide: 'right' },
  { columns: 3, component: 'card' },
  { columns: 3, component: 'card' },
  { columns: 6, component: 'card', size: 'medium' },
];

export default config;
