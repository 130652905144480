import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Link from 'components/shared/link';
import Heading from 'components/shared/heading';

import styles from './additional-links.module.scss';

const cx = classNames.bind(styles);

const AdditionalLinks = ({ title, items }) => (
  <section className={cx('wrapper')}>
    <Heading className={cx('title')} tag="h2" size="xl">
      {title}
    </Heading>

    <div className={cx('items')}>
      {items.map((item, index) => (
        <Link className={cx('item')} to={item.link.url} key={index}>
          <div className={cx('item-head')}>
            <Heading className={cx('item-title')} tag="h3" size="lg">
              {item.link.title}
            </Heading>
          </div>
          <div className={cx('item-footer')}>
            <span className={cx('item-read-more')}>Read more</span>
          </div>
        </Link>
      ))}
    </div>
  </section>
);

AdditionalLinks.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default AdditionalLinks;
