import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import ArrowIcon from './svg/arrow.inline.svg';

import styles from './blog-post-card.module.scss';

const cx = classNames.bind(styles);

const BlogPostCard = (props) => {
  const {
    className,
    title,
    description,
    date,
    categories,
    image,
    url,
    size,
    contentSide,
    innerClassName,
    contentWrapperClassName,
    imageWrapperClassName,
    imageClassName,
    imageFadeIn,
  } = props;

  const wrapperClassName = cx('wrapper', size, contentSide, className);
  const imageLoading = imageFadeIn ? 'lazy' : 'eager';

  return (
    <article className={wrapperClassName}>
      <Link className={cx('inner', innerClassName)} to={url}>
        <div className={cx('content-wrapper', contentWrapperClassName)}>
          <div className={cx('content')}>
            {!!categories?.length &&
              !!categories.filter(({ name }) => name !== 'Uncategorized').length && (
                <div className={cx('categories')}>
                  {categories.map(({ name }, index) => (
                    <span className={cx('category')} key={index}>
                      {name}
                    </span>
                  ))}
                </div>
              )}

            <Heading className={cx('title')} tag="h4" size="md">
              {title}
            </Heading>
            <p className={cx('description')}>{description}</p>
            <div className={cx('footer')}>
              <div className={cx('read-more')}>
                <span>Read more</span>
                <ArrowIcon />
              </div>
              {date && <span className={cx('date')}>{date}</span>}
            </div>
          </div>
        </div>
        <div className={cx('image-wrapper', imageWrapperClassName)}>
          <GatsbyImage
            className={cx('image', imageClassName)}
            loading={imageLoading}
            image={getImage(image)}
            alt={title}
          />
        </div>
      </Link>
    </article>
  );
};

BlogPostCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'large']),
  contentSide: PropTypes.oneOf(['left', 'right']),
  innerClassName: PropTypes.string,
  contentWrapperClassName: PropTypes.string,
  imageWrapperClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  imageFadeIn: PropTypes.bool,
};

BlogPostCard.defaultProps = {
  className: null,
  date: null,
  size: null,
  contentSide: 'left',
  innerClassName: null,
  contentWrapperClassName: null,
  imageWrapperClassName: null,
  imageClassName: null,
  imageFadeIn: true,
};

export default BlogPostCard;
