import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Link from 'components/shared/link';

import styles from './banner.module.scss';

const cx = classNames.bind(styles);

const Banner = ({ className, image, link, altText }) => (
  <Link className={className} to={link}>
    <GatsbyImage className={cx('image')} image={getImage(image)} alt={altText} />
  </Link>
);

Banner.propTypes = {
  className: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  link: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  className: null,
};

export default Banner;
