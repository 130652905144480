import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import HubspotForm from 'components/shared/hubspot-form';
import { FORM_IDS } from 'constants/form';
import { BUTTON_THEMES } from 'constants/button';
import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';
import dotPattern from './svg/dot-pattern.svg';

import styles from './subscribe.module.scss';

const cx = classNames.bind(styles);

const Subscribe = ({ className, title }) => (
  <div className={cx('wrapper', className)}>
    <Heading className={cx('title')} tag="h3" color="tertiary">
      {title}
    </Heading>

    <div className={cx('form')}>
      <HubspotForm
        targetId="hubspotBlogSubscribeForm"
        formId={FORM_IDS.SUBSCRIBE}
        buttonTheme={BUTTON_THEMES.PRIMARY}
      />
    </div>

    <img className={cx('shape-1')} src={shape1} alt="" />
    <img className={cx('shape-2')} src={shape2} alt="" />
    <img className={cx('dot-pattern-1')} src={dotPattern} alt="" />
    <img className={cx('dot-pattern-2')} src={dotPattern} alt="" />
  </div>
);

Subscribe.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Subscribe.defaultProps = {
  className: null,
};

export default Subscribe;
