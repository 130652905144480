import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import HubspotForm from 'components/shared/hubspot-form';
import { FORM_IDS } from 'constants/form';
import { BUTTON_THEMES } from 'constants/button';

import MainContext from 'context/main';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';
import dotPattern from './svg/dot-pattern.svg';

import styles from './subscribe.module.scss';

const cx = classNames.bind(styles);

const Subscribe = () => {
  const {
    sharedBlocks: {
      subscribe: {
        acf: { title, description },
      },
    },
  } = useContext(MainContext);

  return (
    <section className={cx('container', 'wrapper')}>
      <div className={cx('inner')}>
        <Heading className={cx('title')} tag="h4" color="tertiary">
          {title}
        </Heading>
        <p className={cx('description')}>{description}</p>

        <div className={cx('form')}>
          <HubspotForm
            targetId="hubspotSharedSubscribeForm"
            formId={FORM_IDS.SUBSCRIBE}
            buttonTheme={BUTTON_THEMES.PRIMARY}
          />
        </div>

        <img className={cx('shape-1')} src={shape1} alt="" loading="lazy" aria-hidden />
        <img className={cx('shape-2')} src={shape2} alt="" loading="lazy" aria-hidden />
        <img className={cx('dot-pattern')} src={dotPattern} alt="" loading="lazy" aria-hidden />
      </div>
    </section>
  );
};

export default Subscribe;
