/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import FeaturedPost from 'components/pages/blog/featured-post';
import Categories from 'components/shared/categories';
import Grid from 'components/pages/blog/grid';
import Pagination from 'components/pages/blog/pagination';
import Subscribe from 'components/shared/subscribe';

const Blog = ({ data: { wpPage: data, posts }, pageContext, location }) => (
  <LayoutMain
    seo={{ ...data.seo, date: pageContext.date }}
    footerTheme="with-border"
    withBanner={pageContext.showBanner}
    withBottomOffset
    pageContext={pageContext}
    location={location}
  >
    <FeaturedPost
      title={data.acf.featuredPost.title}
      description={data.acf.featuredPost.acf.description}
      date={data.acf.featuredPost.date}
      categories={data.acf.featuredPost.categories.nodes}
      image={data.acf.featuredPost.acf.image.localFile.childImageSharp}
      url={data.acf.featuredPost.uri}
    />
    <Categories
      categories={pageContext.categories}
      pageRootPath={data.uri}
      activeCategoryId={pageContext.categoryId}
    />
    <Grid
      items={posts.nodes}
      additionalLinksTitle={data.acf.additionalLinksTitle}
      additionalLinksItems={data.acf.additionalLinksItems}
      subscribeBannerTitle={data.acf.subscribeBannerTitle}
      imageBannerImage={data.acf.imageBannerImage.localFile.childImageSharp}
      imageBannerImageAltText={data.acf.imageBannerImage.altText}
      imageBannerLink={data.acf.imageBannerLink}
      bestArticlesBannerTitle={data.acf.bestArticlesBannerTitle}
      bestArticlesBannerPosts={data.acf.bestArticlesBannerPosts}
    />
    {pageContext.pageCount > 1 && (
      <Pagination
        pageCount={pageContext.pageCount}
        currentPageIndex={pageContext.currentPageIndex}
        pageRootPath={data.uri}
        categoryPath={pageContext.categoryPath}
      />
    )}
    <Subscribe />
  </LayoutMain>
);

export default Blog;

export const query = graphql`
  query ($id: String!, $featuredPostId: String!, $categoryId: String, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      uri
      acf {
        featuredPost {
          ... on WpPost {
            title
            content
            uri
            date(formatString: "MMMM D, YYYY")
            categories {
              nodes {
                name
              }
            }
            acf {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 480)
                  }
                }
              }
              description
            }
          }
        }
        additionalLinksTitle
        additionalLinksItems {
          link {
            title
            url
          }
        }
        subscribeBannerTitle
        imageBannerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 480)
            }
          }
          altText
        }
        imageBannerLink
        bestArticlesBannerTitle
        bestArticlesBannerPosts {
          post {
            ... on WpPost {
              title
              uri
              acf {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 54, width: 54)
                    }
                  }
                }
              }
            }
          }
        }
      }
      ...wpPageSeo
    }
    posts: allWpPost(
      filter: {
        id: { ne: $featuredPostId }
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        uri
        categories {
          nodes {
            name
          }
        }
        acf {
          largeImage: image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 480)
              }
            }
          }
          mediumImage: image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 360)
              }
            }
          }
          defaultImage: image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 280)
              }
            }
          }
          description
        }
      }
    }
  }
`;
