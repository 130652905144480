import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import styles from './best-articles.module.scss';

const cx = classNames.bind(styles);

const BestArticles = ({ className, title, items }) => (
  <div className={cx('wrapper', className)}>
    <Heading className={cx('title')} tag="h3">
      {title}
    </Heading>

    {items.map(({ post }, index) => (
      <Link className={cx('item')} to={post.uri} key={index}>
        <GatsbyImage
          className={cx('item-image')}
          image={getImage(post.acf.image.localFile.childImageSharp)}
          alt={title}
        />
        <h4 className={cx('item-title')}>{post.title}</h4>
      </Link>
    ))}
  </div>
);

BestArticles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ post: PropTypes.objectOf(PropTypes.any) })).isRequired,
};

BestArticles.defaultProps = {
  className: null,
};

export default BestArticles;
