import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import BlogPostCard from 'components/shared/blog-post-card';
import shape from './svg/shape.svg';

import styles from './featured-post.module.scss';

const cx = classNames.bind(styles);

const FeaturedPost = (props) => (
  <div className={cx('wrapper')}>
    <div className="container">
      <div className="columns">
        <div className="column is-9-tablet is-8-desktop">
          <BlogPostCard
            {...props}
            size="large"
            contentSide="right"
            innerClassName={cx('card-inner')}
            contentWrapperClassName={cx('card-content-wrapper')}
            imageWrapperClassName={cx('card-image-wrapper')}
            imageClassName={cx('card-image')}
            imageFadeIn={false}
          />
        </div>
      </div>
      <img className={cx('shape')} src={shape} alt="" />
    </div>
  </div>
);

FeaturedPost.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string.isRequired,
};

export default FeaturedPost;
